<template>
  <div id="admin-activity">
      <vs-tabs>
        <!--############# ACTIVITIES #############-->
        <vs-tab :label="$t('activities')">
          <!-- delay slider -->
          <div v-if="activitiesLoaded" class="vx-row w-full flex mx-2 mt-4">
            <div class="vx-row w-full flex px-2">
              <div class="vx-col w-full px-2">
                <p class="inline">{{$t('activitiesSince') }} : <strong>{{formattedDelay}}</strong></p>
                <vs-slider class="mt-2 mb-8" ticks step="10" v-model="delay" :min="5" :max="maxDelay"/>
              </div>
            </div>
          </div>
          <!-- USER AVATARS LIST -->
          <vs-divider/>
          <div v-if="activitiesLoaded" class="flex mb-4">
            <div class="w-full flex flex-wrap">
              <div class="m-0 p-0" :key="'userlist_'+tr.user_id" v-for="tr in latestUsers">      
                <vx-tooltip :key="'tooltip_'+tr.user_id" :text="tr.first_name + ' ' + tr.last_name + ' (' + tr.user_email + ')'">
                  <div @click="filterByUser(tr.user_id)">
                    <user-avatar class="m-0 p-0"
                      :key="'avatar_'+tr.user_id+'_2'"
                      :user="tr"
                      :size="filteredByUserId == tr.user_id ? '65px' : 'large'"
                    />
                  </div>
                </vx-tooltip>
              </div>
            </div>
          </div>
          <vs-divider/>

          <!-- STATITICS ROW -->
          <div v-if="activitiesLoaded" class="vx-row my-0 py-0">
            <div class="vx-col w-full sm:w-1/1 md:w-1/3 lg:w-1/3 xl:w-1/3">
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="UsersIcon"
                icon-right
                :statistic="latestUsers.length || 0"
                :statisticTitle="$t('activeUsers')"
                color="primary" />
            </div>
            <div class="vx-col w-full sm:w-1/1 md:w-1/3 lg:w-1/3 xl:w-1/3">
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="AirplayIcon"
                icon-right
                :statistic="distinctPortals.length || 0"
                :statisticTitle="$t('Portal') + ' : ' + portalsList"
                color="primary" />
            </div>
            <div class="vx-col w-full sm:w-1/1 md:w-1/3 lg:w-1/3 xl:w-1/3">
              <statistics-card-line
                hideChart
                :class="numberOfErrors > 0 ? 'bg-danger' : ''"
                icon="AlertTriangleIcon"
                icon-right
                :statistic="numberOfErrors"
                :statisticTitle="$t('warnings')"
                :color="numberOfErrors > 0 ? 'rgb(255,255,255)' : ''" />
            </div>

          </div>

          <!-- CONVERSIONS ROW -->
          <div class="w-full">
            <vs-progress v-if="!conversionsLoaded"></vs-progress>
            <vs-table v-if="conversionsLoaded" class="conversions_table" ref="conversions_table" :data="this.liveConversions">
              <template slot="thead">
                <vs-th sort-key="conversion_start_date">{{$t('conversion_start_date')}}</vs-th>
                <vs-th sort-key="portal">{{$t('Portal')}}</vs-th>
                <vs-th class="hidden md:block" sort-key="data_title">{{$t('data_title')}}</vs-th>
                <vs-th sort-key="data_type">{{$t('data_type')}}</vs-th>
                <vs-th sort-key="sender">{{$t('sender')}}</vs-th>
                <vs-th sort-key="progression">
                  {{$t('progression')}}
                </vs-th>
              </template>
              <template slot-scope="{data}">
                <tbody>
                  <template v-for="(tr, indextr) in data">
                    <vs-tr :class="trStyle(tr)" :data="tr" :key="indextr">
                      <vs-td>
                        <p class="hidden bg:block">{{ tr.conversion_start_date | shortDate }}</p>
                        <p>{{ tr.conversion_start_date | hours }}</p>
                      </vs-td>
                      <vs-td>
                        <p class="portal">{{ tr.portal }}</p>
                      </vs-td>
                      <vs-td class="hidden md:block">
                        <p class="layer_name">{{ tr.data_title }}</p>
                        <p class="layer_key">{{ tr.layer_key }}</p>
                      </vs-td>
                      <vs-td>
                        <p class="layer_type">{{ $t(tr.data_type) | title }}</p>
                      </vs-td>
                      <vs-td class="inline-flex user-col">
                        <ul class="flex flex-wrap items-center">
                          <li class="inline-flex items-end">
                            <user-avatar class="hidden md:block" :user="tr" size="small" :key="'avatar_'+tr.user_id" />
                          </li>
                          <li class="inline-flex items-end">
                            {{tr.first_name+' '+tr.last_name | capitalize}}
                          </li>
                        </ul>
                      </vs-td>
                      <vs-td class="whitespace-no-wrap">
                        <ul class="flex flex-wrap items-center">
                          <span v-if="tr.conversion_status === 'waiting' || tr.conversion_step === '0'">{{$t('pendingConversion')}}</span>
                          <template v-if="tr.conversion_status == 'alive'" >
                            <p>{{$t('step') | capitalize}} {{tr.conversion_step}}</p>
                            <vs-progress :percent="Number(tr.progression)*100" class="shadow-md" />
                          </template>
                          <div v-if="tr.conversion_status == 'success'">
                            <feather-icon class="w-6 h-6 mr-2" icon="CheckCircleIcon"/>
                            <p><a target="_blank" :href="`${this.$store.state.viewerBaseUrl}/?portal_id=${tr.portal}&layer_key=${tr.layer_key}`">{{$t('success')}}</a></p>
                            <p>{{ parseFloat(tr.layer_size) | prettyBytes }}</p>
                          </div>
                        </ul>
                      </vs-td>
                    </vs-tr>
                  </template>
                </tbody>
              </template>
            </vs-table>
          </div>

          <!-- ACTIVITY ROW -->
          <div class="mt-8 data-list-container">
            <vs-progress v-if="!activitiesLoaded"></vs-progress>
            <vs-table v-if="activitiesLoaded" ref="activities_table" :data="this.liveUsersActivities">
              <template slot="thead">
                  <vs-th sort-key="date">{{$t('Date')}}</vs-th>
                  <vs-th class="hidden md:block" sort-key="portal">{{$t('Portal')}}</vs-th>
                  <vs-th sort-key="user">{{$t('User')}}</vs-th>
                  <vs-th sort-key="session">{{$t('Session')}}</vs-th>
                  <vs-th sort-key="project">{{$t('Project')}}</vs-th>
                  <vs-th sort-key="type">{{$t('Type')}}</vs-th>
                  <vs-th class="hidden md:block" ort-key="details">{{$t('details')}}</vs-th>
                  <vs-th sort-key="result">{{$t('Result')}}</vs-th>
              </template>
              <template slot-scope="{data}">
                <tbody>
                  <template v-for="(tr, indextr) in data">
                      <vs-tr :data="tr" :key="indextr" :class="trStyle(tr)" v-if="filteredByUserId === null || (filteredByUserId != null && tr.user_id == filteredByUserId)">
                          <vs-td class="date-col">
                              <p class="hidden md:block">{{ tr.timestamp | shortDate }}</p>
                              <p>{{ tr.timestamp | hours }}</p>
                          </vs-td>
                          <vs-td class="hidden md:block portal-col">
                              <p class="portal_nicename">{{ tr.portal_nicename }}</p>
                          </vs-td>
                          <vs-td class="user-col">
                              <ul class="flex flex-wrap items-center">
                              <li class="inline-flex items-end">
                                <user-avatar class="hidden md:block" :user="tr" size="small" :key="'avatar_'+tr.user_id" />
                              </li>
                              <li class="inline-flex items-end">
                                  {{tr.user_id+': '+ decodeURIComponent(tr.first_name + ' ' + tr.last_name) | capitalize}}
                              </li>
                              </ul>
                          </vs-td>
                          <vs-td class="session-col">
                              <p class="session">{{ decodeURIComponent(tr.session_id) }}</p>
                          </vs-td>
                          <vs-td class="project-col">
                              <p v-if="tr.project_id" class="project"><a target="_blank" class="text-white" :href="`/project/${decodeURIComponent(tr.project_slug)}`" >{{ decodeURIComponent(tr.project_id) + ': ' + decodeURIComponent(tr.project_nicename) }}</a></p>
                          </vs-td>
                          <vs-td class="type-col">
                              <p class="type">{{ $t(tr.type) }}</p>
                          </vs-td>
                          <!-- details -->
                          <vs-td class="hidden md:block details-col">
                            <template v-if="tr.type.includes('data')">
                              <vx-tooltip :text="tr.layer_key">
                                <p class="details">{{ decodeURIComponent(tr.uploaded_filename) }}</p>
                              </vx-tooltip>
                            </template>
                          </vs-td>
                          <vs-td class="result-col">
                              <pre>{{ formatResult(tr.result) }}</pre>
                          </vs-td>
                      </vs-tr>
                  </template>
                </tbody>
              </template>
            </vs-table>
            <vs-button class="mt-8 w-full"
                @click="activitiesLines = activitiesLines + seeMoreIncrement">
                {{$t('seeMore') + ' (+' + seeMoreIncrement + ')'}} 
            </vs-button>
            <vs-button class="mt-8 w-full" color="warning"
                @click="activitiesLines = activitiesLines + seeAllIncrement">
                {{$t('seeAll') + ' (+' + seeAllIncrement + ')'}}
            </vs-button>
          </div>
        </vs-tab>

          <!--############# DIAGNOSTICS #############-->
          <vs-tab :label="$t('Diagnostics')">
            <vs-table class="diag_table" ref="diag_table" :data="this.latestUserDiagnostics">
              <template slot="thead">
                <vs-th sort-key="sql_timestamp">{{$t('Date')}}</vs-th>
                <vs-th>{{$t('Email')}}</vs-th>
                <vs-th>{{$t('Browser')}}</vs-th>
              </template>
              <template slot-scope="{data}">
                <tbody>
                  <template v-for="(tr, indextr) in data">
                    <vs-tr :data="tr" :key="indextr">

                      <vs-td>
                        <p>{{ tr.sql_timestamp | longDate }}</p>
                      </vs-td>

                      <vs-td>
                        <ul class="flex flex-wrap items-center">
                          <li class="inline-flex items-end">
                            <user-avatar class="hidden md:block" :user="tr" size="small" :key="'avatar_'+tr.user_id" />
                          </li>
                          <li class="inline-flex items-end">
                            {{tr.first_name+' '+tr.last_name | capitalize}} ({{ tr.user_email }})
                          </li>
                        </ul>
                      </vs-td>

                      <vs-td>
                        <vs-tabs>

                          <vs-tab :label="$t('Analysis')">
                            <div class="mb-8">

                              <!-- browser supported and up to date -->
                              <vs-chip v-if="JSON.parse(tr.data).summaryReport.isBrowserSupported == true && JSON.parse(tr.data).summaryReport.isBrowserUpToDate == true"
                              color="primary">
                                <vs-avatar icon-pack="feather" icon="icon-check" />
                                <span>{{JSON.parse(tr.data).summaryReport.browser | capitalize}}</span>
                              </vs-chip>
                              <vs-chip v-else
                              color="danger">
                                <vs-avatar icon-pack="feather" icon="icon-x" />
                                <span>{{JSON.parse(tr.data).summaryReport.browser | capitalize}}</span>
                              </vs-chip>

                              <!-- GPU -->
                              <vs-chip v-if="JSON.parse(tr.data).computedValues.gpuIsNvidiaOrAmd == true && JSON.parse(tr.data).computedValues.gpuIsIntel == false"
                              color="primary">
                                <vs-avatar icon-pack="feather" icon="icon-check" />
                                <span>{{JSON.parse(tr.data).summaryReport.gpuModel | uppercase}}</span>
                              </vs-chip>
                              <vs-chip v-else
                              color="danger">
                                <vs-avatar icon-pack="feather" icon="icon-x" />
                                <span>{{JSON.parse(tr.data).summaryReport.gpuModel | uppercase}}</span>
                              </vs-chip>

                              <!-- WebGL -->
                              <vs-chip v-if="JSON.parse(tr.data).summaryReport.webGL == true"
                              color="primary">
                                <vs-avatar icon-pack="feather" icon="icon-check" />
                                <span>{{$t('WebGL')}}</span>
                              </vs-chip>
                              <vs-chip v-else
                              color="danger">
                                <vs-avatar icon-pack="feather" icon="icon-x" />
                                <span>{{$t('WebGL')}}</span>
                              </vs-chip>

                              <!-- os -->
                              <vs-chip :color="stringColor(JSON.parse(tr.data).summaryReport.os)">
                                <span>{{JSON.parse(tr.data).summaryReport.os | capitalize}}</span>
                              </vs-chip>

                              <!-- city -->
                              <vs-chip :color="stringColor(JSON.parse(tr.data).ipData.city)">
                                <span>{{JSON.parse(tr.data).ipData.city | capitalize}}</span>
                              </vs-chip>
                              
                            </div>
                          </vs-tab>

                          <vs-tab :label="$t('Summary')">
                            <pre>summaryReport: {{ JSON.parse(tr.data).summaryReport }}</pre>
                          </vs-tab>

                          <vs-tab :label="$t('computedValues')">
                            <pre>computedValues: {{ JSON.parse(tr.data).computedValues }}</pre>
                          </vs-tab>

                          <vs-tab :label="$t('Browser')">
                            <pre>device.browser: {{ JSON.parse(tr.data).device.browser }}</pre>
                          </vs-tab>

                          <vs-tab :label="$t('gpu')">
                            <pre>device.gpu: {{ JSON.parse(tr.data).device.gpu }}</pre>
                            <pre>gpuBenchmark: {{ JSON.parse(tr.data).gpuBenchmark }}</pre>
                          </vs-tab>

                          <vs-tab :label="$t('WebGL')">
                            <pre>gl: {{ JSON.parse(tr.data).gl }}</pre>
                          </vs-tab>

                          <vs-tab :label="$t('ipData')">
                            <pre>ipData: {{ JSON.parse(tr.data).ipData }}</pre>
                          </vs-tab>

                          <vs-tab :label="$t('Full')">
                            <pre>{{ JSON.parse(tr.data) }}</pre>
                          </vs-tab>

                        </vs-tabs>
                      </vs-td>

                    </vs-tr>
                  </template>
                </tbody>
              </template>
            </vs-table>
            <vs-button class="mt-8 w-full"
              @click="diagLines = diagLines + seeMoreIncrement">
              {{$t('seeMore') + ' (+' + seeMoreIncrement + ')'}} 
            </vs-button>
            <vs-button class="mt-8 w-full" color="warning"
              @click="diagLines = diagLines + seeAllIncrement">
              {{$t('seeAll') + ' (+' + seeAllIncrement + ')'}}
            </vs-button>
          </vs-tab>

      </vs-tabs>
  </div>
</template>
<script>
import axios from '@/axios.js'
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import moment from 'moment'

export default {
  components: {
    StatisticsCardLine, 
    moment
  },
  data () {
    return {
      api_server_baseurl: this.$appConfig.apiBaseUrl,
      appConfig: this.$appConfig,
      getAppLatestActivity: 'getAppLatestActivity',
      getAppLatestUsers: 'getAppLatestUsers',
      getAppLatestUserDiagnostics: 'getAppLatestUserDiagnostics',
      getAppAliveConversions: 'getAppAliveConversions',
      // getServerCharge: 'getServerCharge',
      // getVmMonitoringValues: 'getVmMonitoringValues',
      delay : 240, 
      maxDelay : 2880, 
      activitiesStart : 0,
      activitiesLines : 200,
      diagStart : 0,
      diagLines : 5,
      seeMoreIncrement : 20,
      seeAllIncrement : 500,
      latestUsers: [],
      latestUserDiagnostics: [],
      liveUsersActivities: [],
      previousActivities: [],
      timer: '', 
      activitiesLoaded: false,
      filteredByUserId: null,
      activitiesRefreshFrequency: 2000, 
      diagsRefreshFrequency: 2000, 
      conversionsRefreshFrequency: 15000, 
      // ressourcesRefreshFrequency: 60000, 
      liveConversions: {},
      conversionsLoaded: false
      // serverCharge: {},
      // chargeLoaded: false,
      // vm_names: ['freol-rimnat', 'freol-calc'],
      // vm_charges: {
      //   'freol-rimnat': {
      //     'cpu_usage': 0,
      //     'ram_usage': 0,
      //     'disk_usage': 0,
      //     'vm_name': 'freol-rimnat'
      //   },
      //   'freol-calc': {
      //     'cpu_usage': 0,
      //     'ram_usage': 0,
      //     'disk_usage': 0,
      //     'vm_name': 'freol-calc'
      //   }
      // }
    }
  },
  methods: {   
    decodeHtml (encodedStr) {
      const parser = new DOMParser
      const dom = parser.parseFromString(`<!doctype html><body>${encodedStr}`, 'text/html')
      const decodedString = dom.body.textContent
      return decodedString
    },
    formatResult (value) {
      if (typeof value != 'undefined' && value && value != '') {
        value = decodeURIComponent(value)
        if (value.startsWith('{') || value.startsWith('[')) {
          /* eslint-disable */
          value = this.decodeHtml(value)
          value = value.replace(/'/g, `"`);
          /* eslint-enable */
          try {
            value = JSON.stringify(JSON.parse(value), null, 2)
          } catch (err) {
            console.log(err)
          } 
        }
      }
      return value
    },
    // ROYBON / génère une couleur unique pour un user_id
    stringColor (value) {
      if (typeof value != 'undefined' && value && value != '') {
        return `#${this.intToRGB(this.hashCode(value.toString()))}`
      } else {
        return ''
      }
    },
    hashCode (str) {
      let hash = 0
      if (typeof str !== undefined) {
        for (let i = 0; i < str.length; i++) {
          hash = str.charCodeAt(i) + ((hash << 5) - hash)
        }
      }
      return hash
    },
    intToRGB (i) {
      const c = (Math.pow(i, 2) & 0x00FFFFFF)
        .toString(16)
        .toUpperCase()
      return '00000'.substring(0, 6 - c.length) + c
    },

    freshness (timestamp) {
      return moment(timestamp).fromNow()
    },
    refreshAll () {
      this.getUsersActivities()
      this.getActiveConversions()
      // this.getAllVmCharge()
    },
    getUsersActivities () {
      const params = `delay=${this.delay}&start=${this.activitiesStart}&lines=${this.activitiesLines}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/user/${this.getAppLatestActivity}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          if (response.data != null && response.data != 'null')  {
            this.previousActivities = this.liveUsersActivities
            this.liveUsersActivities = response.data
            this.activitiesLoaded = true
          } else {
            this.previousActivities = []
            this.liveUsersActivities = []
            this.activitiesLoaded = true
          }
        })
        .catch((error)   => { console.log(error) })
    },
    getLatestUsers () {
      const params = `delay=${this.delay}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/user/${this.getAppLatestUsers}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          if (response.data != null && response.data != 'null')  {
            this.latestUsers = response.data
          } else {
            this.latestUsers = []
          }
        })
        .catch((error)   => { console.log(error) })
    },
    getLatestUserDiags () {
      const params = `delay=${this.delay}&start=${this.diagStart}&lines=${this.diagLines}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/user/${this.getAppLatestUserDiagnostics}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          if (response.data != null && response.data != 'null')  {
            this.latestUserDiagnostics = response.data
          } else {
            this.latestUserDiagnostics = []
          }
        })
        .catch((error)   => { console.log(error) })
    },
    getActiveConversions () {
      const params = `delay=${this.delay}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/conversion/${this.getAppAliveConversions}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          if (response.data != null && response.data != 'null') {
            this.liveConversions = response.data
            this.conversionsLoaded = true
          } else {
            this.liveConversions = []
            this.activitiesLoaded = true
          }
        })
        .catch((error)   => { console.log(error) })
    },
    // getCharge () {
    //   const params = ''
    //   const rimnat_api_call_url = `${this.api_server_baseurl}/public/${this.getServerCharge}?${params}`
    //   axios.get(rimnat_api_call_url, {
    //   })
    //     .then((response) => { 
    //       this.serverCharge = response.data
    //     })
    //     .catch((error)   => { console.log(error) })
    // },
    // getAllVmCharge () {
    //   for (let i = 0; i < this.vm_names.length; i++) {
    //     this.getVmCharge(this.vm_names[i])
    //   }
    //   this.chargeLoaded = true
    // },
    // getVmCharge (hostname) {
    //   const rimnat_api_call_url = `${this.api_server_baseurl}/public/${this.getVmMonitoringValues}?vm_name=${hostname}`
    //   axios.get(rimnat_api_call_url, {
    //   })
    //     .then((response) => { 
    //       this.vm_charges[hostname] = response.data
    //     })
    //     .catch((error)   => { console.log(error) })
    // },
    trStyle (tr) {
      let returned = ''
      if (typeof tr.result != 'undefined') {
        if (tr.result.includes('error') || tr.result.includes('warning') || tr.result.includes('fail')) {
          returned = `${returned} warning-line`
        }
        if (tr.type.toLowerCase().includes('rdp') || tr.result.toLowerCase().includes('rdp') || tr.result.toLowerCase().includes('grid rtx6000') || tr.result.toLowerCase().includes('178.237.110.14')) {
          returned = `${returned} rdp`
        } else if (tr.rdp_token != null && tr.rdp_token != '') {
          returned = `${returned} rdp`
        } else if (tr.type != null && tr.type == 'sentry_error') {
          returned = `${returned} sentry_error`
        }
      }
      if (typeof tr.timestamp != 'undefined') {
        if (moment(tr.timestamp).fromNow().includes('seconds')) {
          returned = `${returned} fresh`
        }
      }
      if (typeof tr.conversion_start_date != 'undefined') {
        if (moment(tr.conversion_start_date).fromNow() == 'a few seconds ago') {
          returned = `${returned} fresh`
        }
      }
      return returned
    },
    filterByUser (user_id) {
      if (this.filteredByUserId == user_id) {
        this.filteredByUserId = null
      } else {
        this.filteredByUserId = user_id
      }
    }
  },
 
  computed: {
    isSuperAdmin () {
      return typeof this.$store.state.current_user_global_caps.admin_caps == 'object' && 
        parseInt(this.$store.state.current_user_global_caps.admin_caps.can_global_manage) === 1
    },
    isCustomerServices () {
      return typeof this.$store.state.current_user_global_caps.admin_caps == 'object' && 
        parseInt(this.$store.state.current_user_global_caps.admin_caps.can_services_access) === 1
    },
    isGhost () {
      return typeof this.$store.state.current_user_global_caps.admin_caps == 'object' && 
        parseInt(this.$store.state.current_user_global_caps.admin_caps.is_ghost) === 1
    },
    formattedDelay () {
      const hours   = Math.floor(this.delay / 60)
      const minutes = Math.floor((this.delay - (hours * 60)))
      let returned = ''
      if (hours > 0) returned += `${hours} ${this.$t('hours')} `
      if (minutes > 0) returned += `${minutes} ${this.$t('minutes')}`
      return returned
    },
    distinctPortals () {
      if (this.latestUsers != null && this.latestUsers.length > 0) {
        return [...new Map(this.latestUsers.map(item => [item.portal_nicename, item])).values()]
      } else {
        return 0
      }
    },
    portalsList () {
      return Object.entries(this.distinctPortals)
        .map(item => item[1].portal_nicename)
        .join(', ')
    },
    numberOfErrors () {
      if (this.liveUsersActivities != null && this.liveUsersActivities.length > 0) {
        const apiErrors = this.liveUsersActivities.map(item => item.result).filter(c => c === 'error')
        const sentryErrors = this.liveUsersActivities.map(item => item.type).filter(c => c === 'sentry_error')
        return apiErrors.length + sentryErrors.length
      } else {
        return 0
      }
    }
  },

  beforeMount () {
  },
  beforeDestroy () {
    clearInterval(this.timer)
    document.body.classList.remove('theme-dark')
  },
  created () {
    this.timer = setInterval(this.getLatestUsers, this.activitiesRefreshFrequency)
    this.timer = setInterval(this.getLatestUserDiags, this.diagsRefreshFrequency)
    this.timer = setInterval(this.getUsersActivities, this.activitiesRefreshFrequency)
    this.timer = setInterval(this.getActiveConversions, this.conversionsRefreshFrequency)
    // this.timer = setInterval(this.getAllVmCharge, this.ressourcesRefreshFrequency)
  },
  mounted () {
    document.body.classList.add('theme-dark')
    this.refreshAll()
  },

  watch: {
    liveUsersActivities: function () {
      if (JSON.stringify(this.previousActivities) != JSON.stringify(this.liveUsersActivities)) {
        this.$vs.notify({
          color:'primary',
          position:'bottom-right',
          title:this.$t('newActivitiesDetected')
        })  
      }  
    }
  }
}

</script>
<style lang="scss">
.theme-dark{  
  
  font-size: 0.8rem !important;

  .vs-con-table .vs-con-tbody .vs-table--tbody-table .tr-values .vs-table--td {
    padding: 0px 10px !important;
    line-height: 0.9rem;
  }
  .user-col, .project-col {
    // width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
  }
  .type-col {
    // width: 200px;
  }
  .result-col {
    overflow-y: auto;
    margin: 0px;
    padding: 0px;
  }
  .date-col, .portal-col{
    // width: 120px;
  }

  .fresh {
    background: rgba(var(--vs-primary),1) !important;
    color:#fff;
  }
  .rdp {
    background: #8a0aff !important;
    color:#fff;
  }
  .sentry_error {
    background: rgba(var(--vs-danger), 1) !important;
    color:#fff;
  }
  .active-warnings {
    background: rgba(var(--vs-warning),1) !important;
    color:#fff;
  }
  .vs-navbar {
    display: none;
    height: 0px;
  }
  .vx-navbar-wrapper {
    display: none;
    height: 0px;
  }
  .layer_key {
    color: #5f5f5f !important;
    font-size: 0.8rem !important;
    font-family: monospace;
    font-weight: 100;
  }
  .conversions_table {
    width: 100%;
    overflow: hidden;
  }
  .vs-con-table .vs-con-tbody {
    overflow: hidden;
  }


  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .layer_id {
      max-width: 23rem;
    }

    .inline {
      text-align: left;
      display: inline;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 0.2rem;
      padding: 0 0rem;

      tr{
          font-size: 0.85rem;
          padding: 0px;
          margin: -10px !important;
          
          box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
          td{
            overflow-wrap: anywhere;
            padding: 0px;
            &:first-child{
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }
            &:last-child{
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
          }
          td.td-check{
            padding: 0px !important;
          }
      }
    }

    .vs-table--thead{
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text{
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check{
        padding: 0 0px !important;
      }
      tr{
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }

    .warning-line {
      background: rgba(var(--vs-warning),1) !important;
      color: rgba(0,0,0,0.8) !important;
    }
    .active_filtered_user {
      opacity: 1;
    }
    .inactive_filtered_user {
      opacity: 0,5;
    }
  }
}

</style>
